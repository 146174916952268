<template src="./edit-company.html"></template>
<style src="./edit-company.scss" scoped lang="scss"></style>
<style src="./edit-company-non-scoped.scss" lang="scss"></style>
<script>
import Vue from 'vue';
import { mapState, mapActions, mapGetters} from 'vuex';
import { maxLength, url } from 'vuelidate/lib/validators';

import uploadPromoImage from '@/views/components/uploadImages/uploadPromoImage.vue';
import uploadPromoFiles from '@/views/components/uploadFiles/uploadPromoFiles.vue';
import uploadPhotosAndVideoPromopage from '@/views/components/uploadFiles/uploadPhotosAndVideoPromopage.vue';
import uploadProductImage from '@/views/components/uploadImages/uploadProductImage.vue';
import countryCityFields from '@/views/components/promoPage/countryCityFields.vue';
import chooseCompanyCategories from '@/views/components/popups/chooseCompanyCategories.vue';
import ValidationHelper from '@/_helpers/validation.helper';
import draggable from 'vuedraggable';
import PhoneInput from '@/_components/phone-input/phoneInput.vue';

import IconCopy from '@/_modules/icons/components/icon-copy'

import _cloneDeep from 'lodash.clonedeep';
import _isEqual from 'lodash.isequal';
import { MeetingRoomType } from '@/_modules/meeting-rooms/types/meeting-room-type.enum';
import { BroadcastType } from '@/_types/broadcasts/broadcast-type.enum';
import EventHelper from '@/_helpers/event.helper';
import { TimeStatus } from '@/_types/time-status.enum';
import { PromoContactRole } from "@/_types/promo-page/promo-contact-role.enum";

const editCompany = Vue.extend({
  name: 'edit-company',
  components: {
    uploadPhotosAndVideoPromopage,
    uploadPromoFiles,
    uploadPromoImage,
    uploadProductImage,
    countryCityFields,
    chooseCompanyCategories,
    draggable,
    PhoneInput,
    IconCopy
  },
  props: {
    eventLanguages: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    ...mapGetters({
      contact: 'promoPageStore/contact',
      event: '_eventStore/event',
    }),
    ...mapState('locationStore', ['countryList', 'cityList', 'loading']),
    ...mapState('contactStore', ['callContact', 'editContact', 'editContactLoading', 'editContactSuccess']),
    ...mapState('promoStore', ['editPromoPageLoading', 'editPromoPageSuccess', 'promoOwnerId', 'productsListStandalone']),
    ...mapState({
      contact_info: state => state.contactStore.contact,
      promoPage: state => state.promoStore.myPromoPage,
      myPromoPageLoading: state => state.promoStore.myPromoPageLoading,
      eventInfo: state => state.eventStore.eventInfo,
      eventTags: state => state.eventStore.eventTags,
    }),
    ...mapGetters('editFormStore', {
      hasUnsavedChanges: 'hasUnsavedChanges',
    }),
    ...mapGetters('_userStore', {
      userInfo: 'user'
    }),

    eventId() {
      return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
    },

    meetingRoomConfig() {
      if (!this.eventId || !this.contact || !this.contact.id) {
        return null;
      }

      return {
        type: MeetingRoomType.BROADCAST,
        broadcastType: BroadcastType.PROMO_CONTACT,
        eventId: this.eventId,
        contactId: this.contact.id,
      };
    },

    externalId() {
      return this.promoPage ? this.promoPage.external_id : null;
    },

    isLanguageSavingNeeded() {
      // Return true when we have the language list, it is not empty and the current edit language is set
      return !!(this.eventLanguages && (this.eventLanguages.length > 0) && this.currentEditLanguage);
    },

    currentEditLanguage() {
      if (this.eventLanguages && this.eventLanguages.length > 0) {
        if (this.selectedLanguageTab) {
          return this.selectedLanguageTab;
        }
        return this.eventLanguages[0];
      }

      // TODO: is it ok? Maybe not.
      // When a user visits by URL, say, ru/events/365/promo/edit/company
      // they see the promopage data in Russian, but the selected tab is English
      // This can save bad data into the DB.
      // Because of this, I hardcoded a default language here so that
      // this.getPromopageContactId always has some language in this.currentEditLanguage
      return 'en';
    },

    /* We need this here, too because user can reach the page by URL */
    canSeeCompanyForm() {
      // Show company form only when promo-page has id
      if (this.promoPage) {
        return !!this.promoPage.id;
      }
      return false;
    },

    /* Options for product drag-sorting
     */
    productDragOptions() {
      return {
        animation: 200,
        group: 'productList',
        disabled: false,
      };
    },

    /* Should the add colleague button be disabled
      */
    isAddColleagueDisabled() {
      if (this.addColleagueButtonDisabled)
        return true;

      return this.companyEdit.phone_number.length < 8;
    },

    isPushProductButtonDisabled() {
      return this.isProductListUpdating
          || !this.companyEdit.addProductItem.photo_url
          || !this.companyEdit.addProductItem.description
          || !this.companyEdit.addProductItem.name;
    },

    companyWebsiteUrl: {
      get: function () {
        return this.companyEdit.website || 'https://';
      },
      set: function (url) {
        this.companyEdit.website = url === 'https://' ? '' : url;
      },
    },
  },
  watch: {
    code: {
      handler() {
        this.code = this.code.trim()
      }
    },
    contact_info: {
      deep: true,
      immediate: true,
      handler() {
        if (this.contact_info && this.contact_info.id) {
          this.getPromoPageByContactId();
        }
      },
    },

    promoPage: {
      deep: true,
      handler() {

        this.initializePromoPage();

      }
    },

    editPromoPageSuccess: {
      handler() {
        if (this.editPromoPageSuccess) {
          this.removeEditPromoPageMessageTimeout = setTimeout(this.removeEditPromoPageMessage, 7000);
        }
      }
    },

    companyEdit: {
      deep: true,
      handler() {
        this.checkUnsavedChanges();
      }
    },

    /* We need to clear the status message on language change.
     * After testing, a watcher seems to be better for this than v-if logic
     */
    currentEditLanguage: {
      handler() {
        this.removeEditPromoPageMessage();
      }
    },

    'companyEdit.website': {
      handler() {
        this.checkUnsavedChanges();
      }
    },

    'companyEdit.addProductItem': {
      handler() {
        this.checkUnsavedChanges();
      }
    },

    promoPageFiles: {
      handler() {
        this.checkUnsavedChanges();
      }
    },

    promoPagePhotos: {
      handler() {
        this.checkUnsavedChanges();
      }
    },

    promoPageTags: {
      handler() {
        this.checkUnsavedChanges();
      }
    },

    promoPageProducts: {
      handler() {
        this.checkUnsavedChanges();
      }
    },

  },
  mounted() {
    this.$store.dispatch('locationStore/countryList');
    this.populateEventTags();
  },
  data() {
    return {
      removeEditPromoPageMessageTimeout: -1,
      selectedLanguageTab: '',
      isProductListUpdating: false,
      productDrag: false,
      showCategoryChooser: false,
      addColleagueError: false,
      deleteColleagueError: false,
      addColleagueButtonDisabled: false,
      code: '',
      addContactError: '',
      copyCodeSuccess: '',
      copySuccessTimeOut: '',
      accessKeyData: {
        eventId: '',
        externalId: '',
        code: '',
      },
      phoneMask: '',
      //
      companyEdit: {
        website: '',
        external_id: '',
        logo_url: '',
        description: '',
        title: '',
        phone_number: '',
        country_id: 0,
        city_id: 0,
        addProductItem: {
          photo_url: '',
          name: '',
          description: '',
          sorting: 0,
        },
      },
      oldCompanyEdit: { // Needed to compare with in watch/companyEdit
        website: '',
        external_id: '',
        logo_url: '',
        description: '',
        title: '',
        phone_number: '',
        country_id: 0,
        city_id: 0,
        addProductItem: {
          photo_url: '',
          name: '',
          description: '',
          sorting: 0,
        },
      },
      //
      promoPageFiles: [],
      promoPagePhotos: [],
      promoPageTags: [],
      promoPageTagIds: [],
      promoPageProducts: [],
      teamMembers: [],
    };
  },
  methods: {

    ...mapActions('editFormStore', {
      showConfirmLeavePopup: 'showConfirmLeavePopup',
      hideConfirmLeavePopup: 'hideConfirmLeavePopup',
      setHasUnsavedChanges: 'setHasUnsavedChanges',
      leave: 'leave'
    }),

    onBroadcastChoiceEmbedClick() {
      const meetingRoomConfig = this.meetingRoomConfig;
      if (!meetingRoomConfig) {
        return;
      }
      this.$store.dispatch('_eventStore/setEmbedCodeDialogConfig', meetingRoomConfig);
    },

    onBroadcastChoiceZoomClick() {
      const eventTimeStatus = EventHelper.getEventTimeStatus(this.event);
      if (eventTimeStatus === TimeStatus.PAST) {
        this.$store.dispatch('_eventStore/setIsBroadcastTimeCheckDialogVisible', true);
        return;
      }
      const meetingRoomConfig = this.meetingRoomConfig;
      if (!meetingRoomConfig) {
        return;
      }
      this.$store.dispatch('_eventStore/setZoomSettingsDialogConfig', meetingRoomConfig);
    },

    onBroadcastChoiceOBSClick() {
      const eventTimeStatus = EventHelper.getEventTimeStatus(this.event);
      if (eventTimeStatus === TimeStatus.PAST) {
        this.$store.dispatch('_eventStore/setIsBroadcastTimeCheckDialogVisible', true);
        return;
      }
      const meetingRoomConfig = this.meetingRoomConfig;
      if (!meetingRoomConfig) {
        return;
      }
      this.$store.dispatch('_eventStore/setObsSettingsDialogConfig', meetingRoomConfig);
    },

    checkUnsavedChanges() {
      const result = (
          _isEqual(this.companyEdit, this.oldCompanyEdit)
          && (!this.hasCompanyWebsiteUrlChanged())
          && _isEqual(this.promoPageFiles, this.promoPage.files)
          && _isEqual(this.promoPagePhotos, this.promoPage.photos)
          && _isEqual(this.promoPageTags, this.promoPage.tags)
          && _isEqual(this.promoPageProducts, this.promoPage.products));

      this.setHasUnsavedChanges(!result);
    },

    /* Simple comparison of promoPage.website with companyEdit.website
     * will always return not equal because of visual addition of https://
     */
    hasCompanyWebsiteUrlChanged() {
      if (!this.companyWebsiteUrl || this.companyWebsiteUrl === 'https://') {
        return false;
      } else if (this.companyWebsiteUrl !== this.promoPage.website) {
        return true;
      }
      return false;
    },

    initializePromoPage() {

      this.companyEdit.website = this.promoPage.website;
      this.companyEdit.description = this.promoPage.description;
      this.companyEdit.title = this.promoPage.title;
      this.companyEdit.logo_url = this.promoPage.logo_url;

      if (this.promoPage.country) {
        this.companyEdit.country_id = this.promoPage.country.id;
        this.requestCompanyCityList(this.promoPage.country.id);
      }

      if (this.promoPage.city) {
        this.companyEdit.city_id = this.promoPage.city.id;
      }

      if (this.$refs.uploadCompanyLogo) {
        this.$refs.uploadCompanyLogo.showExternalImage(this.promoPage.logo_url);
      }

      this.promoPageFiles = _cloneDeep(this.promoPage.files);
      this.promoPagePhotos = _cloneDeep(this.promoPage.photos);

      // Products list to display in the form
      if (this.promoPage.products) {
        this.promoPageProducts = _cloneDeep(this.promoPage.products);
      }

      // Tags list to display in the form
      if (this.promoPage.tags) {
        this.promoPageTags = _cloneDeep(this.promoPage.tags); // [{}, {}, ... {}] structure with tag data
        this.promoPageTagIds = this.promoPageTags.map((x) => x.id); // [1,9,3,43,]
      }

      if (this.promoPage.attached_contacts) {
        this.teamMembers = this.promoPage.attached_contacts.filter(function (item) {
          return !!item.role
        });
      }

      // For unsaved changes comparison
      this.oldCompanyEdit = _cloneDeep(this.companyEdit);

    },

    selectLanguageTab(langCode) {
      const leaveAction = () => {
        // No re-requesting if the language is the same
        if (this.selectedLanguageTab !== langCode) {
          this.selectedLanguageTab = langCode;
          this.setHasUnsavedChanges(false);
          this.hideConfirmLeavePopup();
          this.getPromoPageByContactId();
        } else {
          this.selectedLanguageTab = langCode;
        }
        this.removeEditPromoPageMessage();
      }

      if (this.selectedLanguageTab !== langCode && this.hasUnsavedChanges) {
        this.showConfirmLeavePopup(leaveAction);
      } else {
        leaveAction();
      }

    },

    /* Handler for event=tagListUpdated
     */
    displayTags(selectedTags) {
      this.promoPageTags = selectedTags.filter((x) => x.isSelected);
      this.promoPageTagIds = this.promoPageTags.map((x) => x.id);
    },

    /* Populates eventTags in store
     */
    async populateEventTags() { //:TODO //commit goes to eventStore
      const tags = await this.$store.dispatch('eventStore/callEventTags', this.$route.params.eventId);
      if (tags && tags.data && typeof tags.data.List != 'undefined')
        this.$store.commit('eventStore/setEventTags', tags.data.List);
    },

    /* Toggle category chooser popup/overlay
     */
    toggleCategoryChooser() {
      this.showCategoryChooser = !this.showCategoryChooser;
    },

    /* City list requested after company country id is known
     * Separate method because contact form is planned to have
     * country and city, too
     */
    requestCompanyCityList() {
      if (this.companyEdit.country_id) {
        this.$store.dispatch('locationStore/cityList', this.companyEdit.country_id)
      }
    },

    /* City list requested after company country id is known
     */
    companyCountryChangeHandler(value) {
      this.companyEdit.country_id = value.country;
      if (value.city) {
        this.companyEdit.city_id = value.city;
      } else {
        this.companyEdit.city_id = null;
      }
      this.requestCompanyCityList();
    },

    /* returns url() for background-image
      */
    getColleagueAvatar(colleague) {
      let photoUrl = require('@/assets/images/no-avatar-300x300.png');
      if (colleague && colleague.contact && colleague.contact.photo_url) {
        photoUrl = colleague.contact.photo_url;
      }
      return 'url(' + photoUrl + ')';
    },

    /* Gets photos from store and shows them inside upload-photos component
       */
    setPromoPagePhotos() {
      return _cloneDeep(this.promoPagePhotos);
    },

    /* Убираем сообщение об успехе редактирования компании
     * И чистим анимационный таймаут об этом тоже.
     */
    removeEditPromoPageMessage() {
      this.$store.commit('promoStore/setEditPromoPageSuccess', false);
      clearTimeout(this.removeEditPromoPageMessageTimeout);
    },

    /* Приём лого компании промопейджа из события в дочернем компоненте */
    handleLogoUrl(data) {
      this.companyEdit.logo_url = data.image;
    },

    /* Приём документов промопейджа из события в дочернем компоненте */
    handlePromopageFiles(data) {
      this.promoPageFiles = data || [];
      this.checkUnsavedChanges();
    },

    /* Приём списка продукции из события в дочернем компоненте */
    // TODO: rename me
    handlePromopageProducts(data) {
      this.companyEdit.addProductItem.photo_url = data ? data : '';
      this.checkUnsavedChanges();
    },

    /* Приём списка фото и видео (контент для слайдера) из события в дочернем компоненте */
    handlePromoPagePhotosAndVideos(data) {
      this.promoPagePhotos = data || [];
      this.checkUnsavedChanges();
    },

    /* Обработчик нажатия на «Добавить» продукт.
     * Добавляет новому продукту свойство sorting.
     * Кладет урл, фотку и описание в массив promoPageProducts
     * НЕ СОХРАНЯЕТ. Сохранение делается в saveProducts
     */
    pushProductItem() {
      // spreading the object to make a copy of it instead of just a reference of the temporary object
      const productBeingAdded = {...this.companyEdit.addProductItem};

      // updating the sort property in product being added
      productBeingAdded.sorting = this.promoPageProducts.length;

      this.promoPageProducts.push(productBeingAdded);

      // clear addProductItem
      for (const i in this.companyEdit.addProductItem) {
        this.companyEdit.addProductItem[i] = '';
      }

      // Clear the photo from product image uploader
      this.$refs.promoPageProductImageUploader.showExternalImage('');
    },

    /* Обработчик нажатия на «Удалить» продукт.
     * Запускает action и удаляет из массива promoPageProducts
     */
    async deleteProduct(product, index) {
      // If a product has an id, it means that the product
      // has been saved in the DB, have to delete it from there
      if (product.id) {
        // dispatch delete product
        await this.$store.dispatch('promoStore/deletePromoPageProduct', {
          event_id: this.$route.params.eventId,
          external_id: this.externalId,
          product_id: product.id
        })
      }

      // delete an item from the promoPageProducts array
      // for visual change
      this.promoPageProducts.splice(index, 1);
    },

    /* Сохранение продуктов:
       - по нажатию Добавить продукт НЕ СОХРАНЯЕТСЯ, а только помещается в конец временного массива.
       - по нажатию Сохранить список продуктов сохраняется как массив dispatch-промисов.
       - по Promise.all список продуктов обновляется, запустив отдельный action, который не мутирует весь state.promoPage
    */
    async saveProducts() {
      const resultingPromises = [];

      this.isProductListUpdating = true;

      if (this.promoPageProducts) {
        for (let i = 0; i < this.promoPageProducts.length; i++) {

          // Место в списке сортировки
          // Ноль не шлём — бэк игнорит его
          this.promoPageProducts[i].sorting = i + 1;

          // Если есть id, редактируем, а не создаём новый
          // TODO: не отправлять (continue), если нет изменений
          let method = 'put';
          if (this.promoPageProducts[i].id) {
            method = 'patch';
          };

          // Отправляем при создании файла только url и name
          let productInfoToSend = {
            photo_url: this.promoPageProducts[i].photo_url,
            name: this.promoPageProducts[i].name,
            description: this.promoPageProducts[i].description,
            sorting: this.promoPageProducts[i].sorting,
          };

          // Language of data to be saved, if needed
          if (this.isLanguageSavingNeeded && method === 'put') {
            productInfoToSend.lang = this.currentEditLanguage;
          }

          // Array of promises to be used in .all
          const payload = {
            data: productInfoToSend,
            event_id: this.$route.params.eventId,
            external_id: this.externalId
          };

          switch (method) {
            case 'put':
              resultingPromises.push(this.$store.dispatch('promoStore/putPromoPageProduct', payload));
              break;
            case 'patch':
              payload.product_id = this.promoPageProducts[i].id;
              resultingPromises.push(this.$store.dispatch('promoStore/patchPromoPageProduct', payload));
              break;
            default:
              resultingPromises.push(null);
          }

        };

        // Updates the product list with IDs from backend
        await Promise.all(resultingPromises).then(async () => {
          await this.$store.dispatch('promoStore/getProductsFromPromoPage', {
            eventId: this.$route.params.eventId,
            externalId: this.externalId,
          });
          this.promoPageProducts = _cloneDeep(this.productsListStandalone);
        });

        this.isProductListUpdating = false;

      }
    },

    /* Обработчик нажатия в поле ввода номера телефона коллеги
       */
    addColleagueKeyupHandler() {
      this.addColleagueError = '';
      this.companyEdit.phone_number = '+' + this.companyEdit.phone_number
          .replace(/\++/g, '');
    },

    /* Обработчик клика по .add-colleague-button
       */
    async addColleagueByPhoneNumber() {
      let currentPhoneNumber = this.companyEdit.phone_number;
      const payload = {
        event_id: this.$route.params.eventId,
        external_id: this.externalId,
        phone: this.companyEdit.phone_number,
      };

      this.addColleagueButtonDisabled = true;
      const result = await this.$store.dispatch('promoStore/putUserPromoPage', payload);

      if (!result || !result.status === 202) {
        // если результата нет или статус не 202, вероятно, что случилась ошибка )
        this.addColleagueError = true;
      } else {
        // а в эту ветку попадаем, если результат со статусом 202. Успех действия.
        this.addColleagueError = false; // прячем тексты ошибок, если они были
        this.deleteColleagueError = false;

        // TODO: make it more real, like, update state.promo-page.attached_contacts
        this.teamMembers.push({
          contact: {
            user: {
              phone: currentPhoneNumber
            }
          },
          dummy: true // TODO: убрать, когда доделается обновление списка из state
        });

        this.companyEdit.phone_number = '';
      }

      this.addColleagueButtonDisabled = false;

    },

    /* Обработчик клика по .action-delete-team-member
       */
    async deleteColleague(colleague, index) {
      if (!colleague.contact.user.id || colleague.role === PromoContactRole.OWNER) {
        return;
      }

      this.addColleagueError = false;
      this.deleteColleagueError = false;

      let payload = {
        event_id: this.$route.params.eventId,
        external_id: this.externalId,
        user_id: colleague.contact.user.id,
      };

      const result = await this.$store.dispatch('promoStore/deleteUserPromoPage', payload);

      if (!result || !result.status === 202) {
        this.deleteColleagueError = true;
      } else if (typeof index != 'undefined') {
        this.teamMembers.splice(index, 1);
      }

    },

    /* */
    getPromoPageByContactId() {
      let payload = {
        event_id: this.$route.params.eventId,
        contact_id: this.contact_info.id,
      };

      if (this.currentEditLanguage) {
        payload.acceptLanguage = this.currentEditLanguage;// + '-' + this.currentEditLanguage.toUpperCase();
      }

      this.$store.dispatch('promoStore/getMyPromoPageContactId', payload)
    },

    /* Main form save handler
     */
    async submitCompanyForm() {
      this.$v.companyEdit.$touch();
      if (this.$v.companyEdit.$pending || this.$v.companyEdit.$invalid) {
        /* vuelidation error(s) */
        return;
      }

      // Disabling properly in addition to visual disabling in CSS with pointer-events: none
      if (this.isPromoPageLoading || this.$store.state.uploadPromopageFilesStore.loading) {
        return;
      }

      // Отправка данных компании/промопейджа/стенда
      const payload = {
        event_id: this.$route.params.eventId,
        external_id: this.externalId,
        website: this.companyEdit.website,
        logo_url: this.companyEdit.logo_url,
        description: this.companyEdit.description,
        title: this.companyEdit.title,
        country_id: this.companyEdit.country_id,
        city_id: this.companyEdit.city_id,
        tags: this.promoPageTags.map((x) => x.id)
      };

      // Language of data to be saved, if needed
      if (this.isLanguageSavingNeeded) {
        payload.lang = this.currentEditLanguage;
      }

      await this.$store.dispatch('promoStore/updatePromoPage', payload);

      // Отправка документов
      if (this.promoPageFiles) {
        for (let i = 0; i < this.promoPageFiles.length; i++) {
          // Если у файла есть id, значит, не нужно его еще раз отправлять
          // Также параноидальная проверка на отсутствие обязательного поля url
          if (!this.promoPageFiles[i] || this.promoPageFiles[i].id || this.promoPageFiles[i].tempUploadedFlag || !this.promoPageFiles[i].url) {
            continue;
          }

          const filePayload = {
            data: {
              url: this.promoPageFiles[i].url,
              filename: this.promoPageFiles[i].name
            },
            event_id: this.$route.params.eventId,
            external_id: this.externalId
          };

          // Language of data to be saved, if needed
          if (this.isLanguageSavingNeeded) {
            filePayload.data.lang = this.currentEditLanguage;
          }

          await this.$store.dispatch('promoStore/putFileUrlPromoPage', filePayload).then(() => {
            // Setting the local flag for each new object in that array. Since we have an SPA,
            // a user can stay on the page after submitCompanyForm(), which leaves new files
            // without an file.id field which we can't yet update from the backend
            // using .splice because of https://vuejs.org/v2/guide/reactivity.html#For-Arrays
            // TODO: update file objects with id after saving on backend, if that is possible at the moment
            this.promoPageFiles.splice(i, 1, {
              ...this.promoPageFiles[i],
              tempUploadedFlag: true
            });
          });
        }
      }

      // Отправка фото и видео
      if (this.promoPagePhotos) {
        for (let i = 0; i < this.promoPagePhotos.length; i++) {
          // Если у файла есть id, значит, не нужно его еще раз отправлять
          // Также параноидальная проверка на отсутствие обязательного поля url
          if (this.promoPagePhotos[i].id || this.promoPagePhotos[i].tempUploadedFlag || !this.promoPagePhotos[i].url) {
            continue;
          }

          const photoPayload = {
            data: {
              url: this.promoPagePhotos[i].url,
              filename: this.promoPagePhotos[i].name
            },
            event_id: this.$route.params.eventId,
            external_id: this.externalId
          }

          // Language of data to be saved, if needed
          if (this.isLanguageSavingNeeded) {
            photoPayload.data.lang = this.currentEditLanguage;
          }

          await this.$store.dispatch('promoStore/putPhotoVideoUrlPromoPage', photoPayload).then(() => {
            // Setting the local flag for each new object in that array. Since we have an SPA,
            // a user can stay on the page after submitCompanyForm(), which leaves new files
            // without an file.id field which we can't yet update from the backend
            // using .splice because of https://vuejs.org/v2/guide/reactivity.html#For-Arrays
            // TODO: update file objects with id after saving on backend, if that is possible at the moment
            this.promoPagePhotos.splice(i, 1, {
              ...this.promoPagePhotos[i],
              tempUploadedFlag: true
            });
          });
        }
      }

      // Отправка списка продукции
      await this.saveProducts();

      // Несохраненные изменения были сохранены
      this.setCompanyFormSaved();

    },

    /* Marks the editFormStore flag as false
     * Clones local companyEdit into oldCompanyEdit for future checks
     */
    setCompanyFormSaved() {
      this.oldCompanyEdit = _cloneDeep(this.companyEdit);
      this.setHasUnsavedChanges(false);
    },
    setPhoneNumber(phoneNumber) {
      this.companyEdit.phone_number = phoneNumber;
    },
    phoneLengthCheck(phoneMask) {
      this.phoneMask = phoneMask;
    },
    async addContact(){
      if(!this.code) { return; }

     let result =  await this.$store.dispatch('eventStore/activateCode', {
        event_id: this.$route.params.eventId,
        code: this.code
      });
      if (!result || !result.status === 202 || !result.status === 200) {
        this.addContactError = true;
        this.code = ''
      } else {
        this.getPromoPageByContactId()
      }
    },
    addColleague(){
      this.accessKeyData.eventId = this.$route.params.eventId;
      this.accessKeyData.externalId = this.externalId;
      this.accessKeyData.code = `${(~~(Math.random() * 1e8)).toString(19)}${this.randomChars()}`;

      let result =  this.$store.dispatch('contactStore/addContactByCode', this.accessKeyData);
      if (!result || !result.status === 202) {
        // если результата нет или статус не 202, вероятно, что случилась ошибка )
        this.addColleagueError = true;
      } else {
        // а в эту ветку попадаем, если результат со статусом 202. Успех действия.
        this.addColleagueError = false; // прячем тексты ошибок, если они были
        this.deleteColleagueError = false;
      }

      // this.getPromoPageByContactId()
    },
    randomChars(){
      let text = '';
      const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

      for (let i = 0; i < 3; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }

      return text;
    },
    copy() {
      clearTimeout(this.copySuccessTimeOut)
      let successful = this.accessKeyData.code;
      let dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = this.accessKeyData.code;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);

      if (successful) {
        this.copyCodeSuccess = `${this.$t('organizerCabinet.codeHasBeenCopied')}: ${successful}`;
        this.copySuccessTimeOut = setTimeout(() => {
          this.copyCodeSuccess = '';
        }, 3000)
      }
      this.accessKeyData.code = '';
    }
  },
  validations: {
    companyEdit: {
      website: {
        maxLength: maxLength(250),
        url,
      },
      phone_number: {
        isRightLength() {
          return ValidationHelper.isValidPhoneNumberLength(this.phoneMask, this.companyEdit.phone_number)
        },
        phoneValid: ValidationHelper.isValidPhoneNumber,
      },
    },
  },
});
export default editCompany;
</script>


