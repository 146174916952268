<template>
  <div class="block relative">
    <div class="block-title">
      <slot></slot>
    </div>
    <mu-paper class="paper demo-paper" :z-depth="0" v-if="!formData.image">
      <mu-ripple class="mu-ripple demo-1">
        <mu-circular-progress v-if="loading" class="demo-circular-progress" :size="36"></mu-circular-progress>
        <div v-else>
          <mu-icon size="66" :value="imageIcon" color="#57C7FF"></mu-icon>
          <input ref="fileUploadInput" class="upload-input" type="file" @click="rmErr('image')" @change="onFileChangeMain" title=""/>
        </div>
      </mu-ripple>
    </mu-paper>
    <mu-paper class="paper demo-paper paper image-preview" :z-depth="0" v-else>
      <div class="image-preview-blurred" :style="{ backgroundImage: 'url(' + formData.image + ')' }"></div>
      <div class="image-preview-dimmer"></div>
      <div class="image-preview-contain" @click="passClickIntoInput"
           :style="{ backgroundImage: 'url(' + formData.image + ')' }"></div>
      <input ref="fileUploadInput" class="upload-input" type="file" @click="rmErr('image')" @change="onFileChangeMain" title=""/>
    </mu-paper>
    <div class="image-actions">
      <span class="image-action image-action-edit" @click="passClickIntoInput">{{ $t('uploadImages.edit') }}</span>
      <span class="image-action image-action-delete" @click="removeImage" v-if="formData.image">{{ $t('uploadImages.delete') }}</span>
    </div>
    <div v-if="errors.image" class="image-errors">
      <span class="error">{{ $t(errors.image) }}</span>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import uploadFilesMixin from "../../../mixins/upload-files.mixin";

//TODO: remove file after rewrite editUserInfoPage

export default {
  name: 'upload-promo-image',
  computed: {
    ...mapState("uploadPromopageFilesStore", ["image", "uploadedImage", "loading"]),
    ...mapGetters({
      loading: 'uploadPromopageFilesStore/getLoadingPhoto',
    })
  },
  mixins: [uploadFilesMixin],
  props: {
    imageUrl: {
      type: String,
    },
    eventInfo: {
      type: Object
    }
  },
  watch: {
    "formData.image": {
      handler() {
        if (this.validateImage()) {
          this.$emit('imageData', this.formData)
        }
      }
    },
  },
  data: () => ({
    placeholder: null,
    imageIcon: 'add',
    imagePhotos: 'landscape',
    imageSize: '',
    photosSize: '',
    imageType: null,
    errors: {
      image: '',
    },
    formData: {
      image: '',
    }
  }),
  mounted() {
    if (this.imageUrl) {
      this.formData.image = this.imageUrl;
    }
  },
  methods: {
    onFileChangeMain(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.createImage(files[0], true);
    },
    createImage(file, from) {
      // this.file = new Image();
      let reader = new FileReader();

      reader.onload = async () => {

        if (from) {
          this.returnFileSize(file.size, true);
          this.imageType = file.type;
          if (this.validateImage()) {
            this.$store.dispatch("uploadPromopageFilesStore/uploadImage", file).then(() => {
              // Backend has time limit of N sec, after which it closes the connection
              if(!this.uploadedImage.url){
                this.setError({message_key: "errors.validation.connection_timed_out"}, 'image');
                return;
              }
              this.formData.image = this.uploadedImage.url;
            });
          }
        }
      };
      reader.readAsDataURL(file);

    },
    returnFileSize(number) {

      let result;
      if (number < 1024) {
        result = number + 'bytes';
      } else if (number >= 1024 && number < 1048576) {
        result = (number / 1024).toFixed(1) + 'KB';
      } else if (number >= 1048576) {
        result = (number / 1048576).toFixed(1) + 'MB';
      }

      this.imageSize = {
        sizeText: result,
        rawSize: number
      };
    },
    removeImage() {
      this.formData.image = '';
      this.imageType = null;
    },
    validateImage() {
      // Bypass validation on page load
      // on page load, we have image url from API,
      // but we don't have imageType, which is populated
      // only after this.createImage()
      // NB. imageType can be an empty string — if FileReader fails to recognize a MIME type
      if(!this.imageType && this.imageType !== ''){
        return true;
      }
      // Mime type and file size validation
      const allowedTypes = this.getAllowedImageFileTypes();
      if(allowedTypes.indexOf(this.imageType) < 0){
        this.setError({message_key: "errors.validation.image_unsupported_format_promo_logo"}, "image");
        this.removeImage();
        this.imageIcon = 'broken_image'
      } else if (this.imageSize && this.imageSize.rawSize > 10 * 1024 * 1024) {
        this.setError({message_key: "errors.validation.image_size_too_large"}, "image");
        this.removeImage();
        this.imageIcon = 'broken_image'
      } else {
        this.rmErr("image");
        return true;
      }
    },
    setError(err, name) {
      if (name === "image") {
        this.errors.image = err.message_key;
      }
    },
    rmErr(name) {
      if (name === "image") {
        this.errors.image = null;
        this.imageSize = '';
        this.imageIcon = 'add';
      }
    },
    isLoading() {
      return this.$store.state.uploadPromopageFilesStore.loading;
    },
    passClickIntoInput() {
      /* Design has the «edit» link under the image uploader. Have to pass click from here into the input. */
      this.$refs.fileUploadInput.click();
    },
    showExternalImage(image_url) {
      this.formData.image = image_url;
    }
  }
}

</script>
<style scoped lang="scss">
  /* Default styles */
  .block-title {
    color: rgba(0, 0, 0, 0.38);
  }

  .mu-ripple {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #fff;

  }

  img {
    display: block;
    width: auto;
    height: 100%;
    margin: auto auto 10px;
  }

  .upload-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .image-errors {
    font-size: 1.2rem;
    position: absolute;
    top: 110%;
    max-width: 100%;
    padding: 2px 5px;
    border-radius: 4px;
    background-color: #fee;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);

    &::before {
      position: absolute;
      width: 0;
      border-style: solid;
      border-width: 0 5px 8px;
      border-color: transparent transparent #fee;
      content: "";
      bottom: 100%;
      left: 22px;
    }
  }

  .error {
    color: #dd4b39;
  }

  .button-wrapper {
    text-align: center;

    .mu-button {
      margin: 8px 8px 8px 0;
      vertical-align: middle;
    }

    a.mu-button {
      text-decoration: none;
    }
  }

  .button {
    color: #fff;
    background-color: #3369ff;
  }

  .image-actions {
    display: none;

    .image-action {
      cursor: pointer;
      opacity: 0.8;

      &:hover,
      &:focus {
        opacity: 1;
      }
    }
  }

  /* Pass the component class="create-event-header-image" */
  .create-event-header-image {
    height: 100%;
    width: 100%;
    max-height: 150px;

    .demo-paper {
      position: relative;
      width: 100%;
      height: 100%;
      max-width: 266px;
      max-height: 150px;
      margin: 17px 0;
    }

    .mu-ripple {
      background-color: #f4f4f4;
      color: #2196f3;
    }

    .image-preview {
      overflow: hidden;
      background-color: #f4f4f4;

      /* blurred edges like in vertical videos */
      &-blurred {
        background-size: 320%;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;
      }

      /* transparent dimmer */
      &-dimmer {
        background-color: #000;
        opacity: 0.25;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
      }

      /* preview itself using bg size contain */
      &-contain {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
      }
    }

    .image-actions {
      display: block;
      font-size: 1rem;

      .image-action {
        display: inline-block;
        margin-right: 2.4rem;

        &-edit {
          color: #57c7ff;
        }

        &-delete {
          color: #f77;
        }
      }

    }

  }

  /* User avatar styles
   * Pass the component class="upload-image-user"
   */
  .upload-image-user {
    .image-preview {
      width: 50px;
      height: 50px;
      overflow: hidden;
      position: relative;
      background-color: #f4f4f4;
      border-radius: 99px;
      margin-right: 4rem;

      /* blurred edges like in vertical videos */
      &-blurred {
        border-radius: 99px;
        background-size: 320%;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;
      }

      /* transparent dimmer */
      &-dimmer {
        border-radius: 99px;
        background-color: #000;
        opacity: 0.25;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
      }

      /* preview itself using bg size contain */
      &-contain {
        border-radius: 99px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
      }
    }

    .demo-paper,
    .image-actions {
      display: inline-block;
      vertical-align: middle;
    }

    .demo-paper {
      background-color: #f0f0f0;
      width: 50px;
      height: 50px;
      border-radius: 99px;
      margin-right: 4rem;

      .mu-icon {
        font-size: 3rem !important;
        text-align: center;
        width: 5rem !important;
        height: 5rem !important;
        line-height: 5rem !important;
        border-radius: 99px;
        background-color: #f0f0f0;
      }
    }

    .block-title {
      display: none;
    }

    .image-actions {
      font-size: 1rem;

      .image-action {
        display: inline-block;
        margin-right: 2.4rem;

        &-edit {
          color: #57c7ff;
        }

        &-delete {
          color: #f77;
        }
      }

    }
  }


  /* User avatar styles
   * Pass the component class="upload-image-user"
   */
  .upload-image-companylogo {
    .image-preview {
      border-radius: 5px;

      /* blurred edges like in vertical videos */
      &-blurred {
        border-radius: 5px;
      }

      /* transparent dimmer */
      &-dimmer {
        border-radius: 5px;
      }

      /* preview itself using bg size contain */
      &-contain {
        border-radius: 5px;
      }
    }

    .demo-paper {
      border-radius: 5px !important;

      .mu-icon {
        border-radius: 5px !important;
      }
    }

  }

</style>
