<template src="./edit-contact.html"></template>
<style src="./edit-contact.scss" scoped lang="scss"></style>
<style src="./edit-contact-non-scoped.scss" lang="scss"></style>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { email, maxLength, required, url } from 'vuelidate/lib/validators';

import uploadImage from '@/views/components/uploadImages/uploadImage.vue';
import uploadFiles from '@/views/components/uploadFiles/uploadFiles.vue';
import Vue from 'vue';
import _cloneDeep from 'lodash.clonedeep';
import _isEqual from 'lodash.isequal';

const editContact = Vue.extend({
  name: 'edit-contact',
  components: {
    uploadImage,
    uploadFiles,
  },
  computed: {
    ...mapState('locationStore', ['countryList', 'cityList', 'loading']),
    ...mapState('userStore', ['editUserInfo']),
    ...mapState('contactStore', ['callContact', 'editContact', 'editContactLoading', 'editContactSuccess']),
    ...mapState('promoStore', ['editPromoPageLoading', 'editPromoPageSuccess', 'promoOwnerId', 'productsListStandalone']),
    ...mapState({
      userInfo: state => state.userStore.user_info,
      contactInfo: state => state.contactStore.contact,
      promoPage: state => state.promoStore.myPromoPage,
      eventInfo: state => state.eventStore.eventInfo,
    }),
    ...mapGetters('editFormStore', {
      hasUnsavedChanges: 'hasUnsavedChanges',
    }),
    ...mapGetters('contactStore', {
      editContactSuccess: 'editContactSuccess',
    }),
    contactCompanyWebsite: {
      get: function () {
        return this.contact.company_website || 'https://';
      },
      set: function (url) {
        this.contact.company_website = url === 'https://' ? '' : url;
      },
    },
  },
  props: {
    eventLanguages: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  watch: {
    userInfo: {
      deep: true,
      immediate: true,
      handler() {
        this.callContactInfo();
      }
    },
    contactInfo: {
      deep: true,
      handler() {
        this.setContactData();

        // set image
        if (this.$refs.userPhotoUploader && this.contactInfo.photo_url) {
          this.$refs.userPhotoUploader.showExternalImage(this.contactInfo.photo_url);
        }
      }
    },
    editContactSuccess: {
      handler() {
        if (this.editContactSuccess) {
          setTimeout(this.removeEditContactMessage, 9000);
        }
      }
    },
    contact: {
      deep: true,
      handler() {
        this.setHasUnsavedChanges(!_isEqual(this.contact, this.oldContact));
      }
    }
  },
  mounted() {
    this.contact.event_id = this.$route.params.eventId; // TODO: do we need this?
    this.$store.dispatch('locationStore/countryList');
  },
  data() {
    return {
      errors: {
        website: ''
      },
      contactId: -1,
      contact: {
        id: 0,
        event_id: '0',
        name: '',
        surname: '',
        email: '',
        photo_url: '',
        country: '',
        city: '',
        company_name: '',
        company_website: '',
        company_position: '',
        company_description: '',
        files: []
      },
      oldContact: { // Needed to compare with in watch/contact
        id: 0,
        event_id: '0',
        name: '',
        surname: '',
        email: '',
        photo_url: '',
        country: '',
        city: '',
        company_name: '',
        company_website: '',
        company_position: '',
        company_description: '',
        files: []
      }
    };
  },
  methods: {

    ...mapActions('editFormStore', {
      setHasUnsavedChanges: 'setHasUnsavedChanges'
    }),

    callContactInfo() {
      this.$store.dispatch('contactStore/callContact', this.$route.params.eventId);
    },

    setContactData() {

      //if (!this.contactInfo) return;

      // Do not do anything if we already have contact id.
      // No need to re-request promo-page
      if (this.contactId === this.contactInfo.id) {
        return;
      }
      this.contactId = this.contactInfo.id;

      // contact data
      for (let key in this.contact) {
        if (this.contactInfo[key]) {
          this.contact[key] = this.contactInfo[key];
        }
      }

      this.oldContact = _cloneDeep(this.contact);

    },

    handlePhotoUrl(data) {
      this.contact.photo_url = data.image;
      this.$store.commit('contactStore/setContactPhotoURL', data.image);
    },

    handleFiles(data) {
      this.contact.files = data;
    },

    async submitContactForm() {
      this.$v.contact.$touch();
      if (this.$v.contact.$pending || this.$v.contact.$invalid) {
        /* vuelidation error(s) */
        return;
      }

      // Files are sent in a separate loop.
      const contactDataToSend = _cloneDeep(this.contact);
      delete contactDataToSend.files;
      delete contactDataToSend.id; // TODO: recall why do we not send id?

      await this.$store.dispatch('contactStore/editContact', contactDataToSend);
      await this.$store.dispatch('contactStore/callContact', this.$route.params.eventId);
      // Отправка файлов (документов) контакта
      for (let i = 0; i < this.contact.files.length; i++) {
        // Если у файла есть id или tempUploadedFlag, значит, не нужно его еще раз отправлять
        if (this.contact.files[i].id || this.contact.files[i].tempUploadedFlag || !this.contact.files[i].url) {
          continue;
        }
        // Отправляем при создании файла только url и filename
        const fileInfoToSend = {
          url: this.contact.files[i].url,
          filename: this.contact.files[i].name,
          event_id: this.$route.params.eventId
        };
        this.$store.dispatch('contactStore/putContactFile', fileInfoToSend).then(() => {
          this.contact.files.splice(i, 1, {
            ...this.contact.files[i],
            tempUploadedFlag: true
          });
        });
      }

      // Несохраненное было сохранено
      this.setContactFormSaved();

    },

    /* Marks the editFormStore flag as false
     * Clones local contact into oldContact for future checks
     */
    setContactFormSaved() {
      this.oldContact = _cloneDeep(this.contact);
      this.setHasUnsavedChanges(false);
    },

    getPromoPageByContactId() {
      const payload = {
        event_id: this.$route.params.eventId,
        contact_id: this.contactId,
      };
      this.$store.dispatch('promoStore/getMyPromoPageContactId', payload);
    },

    removeEditContactMessage() {
      this.$store.commit('contactStore/setEditContactSuccess', false);
    },

  },
  validations: {

    contact: {
      name: {
        required,
      },
      surname: {
        required,
      },
      email: {
        maxLength: maxLength(250),
        email,
        required,
      },
      company_website: {
        maxLength: maxLength(250),
        url,
      },
      video_stream_url: {
        maxLength: maxLength(250),
        url,
      },
      company_name: {
        required,
      }
    },
  },

});
export default editContact;
</script>
