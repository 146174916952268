<template>
  <div class="block relative">
    <div class="block-title">
      <slot></slot>
    </div>
    <mu-paper class="image-uploader-icon" :z-depth="0" v-if="!formData.image">
      <mu-ripple class="image-uploader-icon-empty">
        <mu-circular-progress v-if="productImageLoading" class="image-uploader-progress" :size="24"></mu-circular-progress>
        <div v-else>
          <mu-icon size="24" :value="imageIcon" color="#57C7FF"></mu-icon>
          <input ref="fileUploadInput" class="upload-input" type="file" @change="onFileChangeMain" title=""/>
        </div>
      </mu-ripple>
    </mu-paper>
    <mu-paper class="image-uploader-icon image-uploader-icon-preview" :z-depth="0" v-else>
      <div class="image-preview-blurred" :style="{ backgroundImage: 'url(' + formData.image + ')' }"></div>
      <div class="image-preview-dimmer"></div>
      <div class="image-preview-contain" @click="passClickIntoInput"
           :style="{ backgroundImage: 'url(' + formData.image + ')' }"></div>
      <input ref="fileUploadInput" class="upload-input" type="file" @change="onFileChangeMain" title=""/>
    </mu-paper>
    <div class="image-actions" v-if="formData.image">
      <span class="image-action image-action-edit" @click="passClickIntoInput">{{ $t('uploadImages.edit') }}</span>
      <span class="image-action image-action-delete" @click="removeImage">{{ $t('uploadImages.delete') }}</span>
    </div>
    <span class="error" v-if="errors.image">{{$t(errors.image)}}</span>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: 'upload-product-image',
  computed: {
    ...mapState("uploadProductsStore", ["productImage", "uploadedProductImage", "productImageLoading"]),
    ...mapGetters({
      getProductImageLoading: 'uploadProductsStore/getProductImageLoading',
    })
  },
  watch: {
    "formData.image": {
      deep: true,
      immediate: true,
      handler() {
        if (this.validateImage()) {
          this.$emit('productImageData', this.formData.image)
        }
      }
    },
  },
  data: () => ({
    placeholder: null,
    imageIcon: 'add',
    imagePhotos: 'landscape',
    imageSize: '',
    photosSize: '',
    imageType: null,
    errors: {
      image: '',
    },
    formData: {
      image: '',
    }
  }),
  mounted() {
    if (this.imageUrl) {
      this.formData.image = this.imageUrl;
    }
  },
  methods: {
    onFileChangeMain(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.createImage(files[0], true);
    },
    async createImage(file, from) {
      // this.file = new Image();
      let reader = new FileReader();

      reader.onload = async () => {

        if (from) {
          this.returnFileSize(file.size, true);
          this.imageType = file.type;
          if (this.validateImage()) {
            this.$store.commit('uploadProductsStore/setProductImageLoading', true);
            let response = await this.$store.dispatch("uploadProductsStore/uploadProductImage", file);
            // Backend has time limit of N sec, after which it closes the connection
            if(!response || !response.url){
              this.$store.commit('uploadProductsStore/setProductImageLoading', false);
              this.setError({message_key: "errors.validation.connection_timed_out"}, 'image');
              return;
            }

            this.formData.image = response.url;
          }
          this.$store.commit('uploadProductsStore/setProductImageLoading', false);
        }
      };
      reader.readAsDataURL(file);

    },
    returnFileSize(number) {

      let result;
      if (number < 1024) {
        result = number + 'bytes';
      } else if (number >= 1024 && number < 1048576) {
        result = (number / 1024).toFixed(1) + 'KB';
      } else if (number >= 1048576) {
        result = (number / 1048576).toFixed(1) + 'MB';
      }

      this.imageSize = {
        sizeText: result,
        rawSize: number
      };
    },
    removeImage() {
      this.formData.image = '';
    },
    validateImage() {
      let isImg;
      if (this.imageType) {
        isImg = this.imageType.split('/');
      }
      if (this.imageSize && this.imageSize.rawSize > 10 * 1024 * 1024) {
        this.setError({message_key: "errors.validation.image_size_too_large"}, "image");
        this.removeImage();
        this.imageIcon = 'broken_image'
      } else if (isImg && isImg[0] !== 'image') {
        this.setError({message_key: "errors.validation.image_upload_failed"}, "image");
        this.removeImage();
        this.imageIcon = 'broken_image'
      } else {
        this.rmErr("image");
        return true;
      }
    },
    setError(err, name) {
      if (name === "image") {
        this.errors.image = err.message_key;
      }
    },
    rmErr(name) {
      if (name === "image") {
        this.errors.image = null;
        this.imageSize = '';
        this.imageicon = 'landscape';
      }
    },
    isLoading() {
      return this.$store.state.uploadProductsStore.productImageLoading;
    },
    passClickIntoInput() {
      /* Design has the «edit» link under the image uploader. Have to pass click from here into the input. */
      this.$refs.fileUploadInput.click();
    },
    showExternalImage(image_url) {
      this.formData.image = image_url;
    }
  }
}
</script>
<style scoped lang="scss">
  /* Default styles */
  .block-title {
    color: rgba(0, 0, 0, 0.38);
  }

  .image-uploader-icon {
    border-radius: 5px;
    width: 56px;
    height: 56px;
    position: relative;
    display: inline-block;
    margin-right: 2rem;
    vertical-align: middle;
  }

  .image-uploader-icon-empty {
    background-color: #f2f2f2;
    width: 100%;
    height: 100%;

    .mu-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .image-uploader-progress {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .image-preview {
    position: absolute;
    border-radius: 5px;
    width: 56px;
    height: 56px;

    /* blurred edges like in vertical videos */
    &-blurred {
      position: absolute;
      border-radius: 5px;
      width: 56px;
      height: 56px;
      background-size: 500%;
      background-repeat: no-repeat;
      background-position: center;
    }

    /* transparent dimmer */
    &-dimmer {
      position: absolute;
      border-radius: 5px;
      width: 56px;
      height: 56px;
      background-color: #000;
      opacity: 0.5;
    }

    /* preview itself using bg size contain */
    &-contain {
      position: absolute;
      border-radius: 5px;
      width: 100%;
      height: 56px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  img {
    display: block;
    width: auto;
    height: 100%;
    margin: auto auto 10px;
  }

  .upload-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .hint {
    position: absolute;
    right: 0;
    bottom: -30px;
    color: grey;
  }

  .error {
    color: #dd4b39;
    font-size: 1.2rem;
    line-height: 1.1;
    position: absolute;
    top: 100%;
    left: 0;
  }

  .button-wrapper {
    text-align: center;

    .mu-button {
      margin: 8px 8px 8px 0;
      vertical-align: middle;
    }

    a.mu-button {
      text-decoration: none;
    }
  }

  .button {
    color: #fff;
    background-color: #3369ff;
  }

  .image-actions {
    font-size: 1rem;
    padding-top: 8px;
    width: 56px;
    display: inline-block;
    flex-direction: row;
    justify-content: space-between;

    .image-action {
      display: inline-block;
      font-size: 10px;
      cursor: pointer;

      &-edit {
        display: none;
        color: #57c7ff;
      }

      &-delete {
        color: #f77;
      }
    }

  }


</style>
